<template>
  <div id="prize">
    <center>
      <b>LEGO® UNIVERSE "BATTLE OF NIMBUS STATION" SWEEPSTAKES</b>
      <br><br>
      <b>OFFICIAL RULES</b>
      <br>
      <b>LEGO® UNIVERSE GAME AND ACTIVE SUBSCRIPTION REQUIRED<br>MANY WILL ENTER; FEW WILL WIN A PRIZE</b>
    </center>
    <p>
      <b>1. OVERVIEW:</b>
    </p>
    <p>
      LEGO® Universe fans from the US, the UK, Canada and Germany who submit their entries between Saturday, August 13 and Sunday, August 14 11:59 PM MST will be eligible to win one of the following prize packages:
    </p>
    <p>
      The top winner from each age group will receive: <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7189&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Mill Village Raid set (#7189)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7188&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms King’s Carriage Ambush set (#7188)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7187&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Escape from the Dragon’s Prison set (#7187)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>, 1 in-game 4 Riders Shirt (+4 Imagination), 1 in-game Nexus Force Shield MkIII (+5 Armor, +3 Imagination), 1 in-game Snazzy Pants (+3 Armor, +3 Imagination), 75 Faction Tokens, and 1 in-game Clear Gem (worth 50,000 coins).
    </p>
    <p>
      The next 3 winners in each age group will receive: <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7188&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms King’s Carriage Ambush set (#7188)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7187&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Escape from the Dragon’s Prison set (#7187)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>, 1 in-game Kinga Hurl Shirt (+3 Imagination), 1 in-game Nexus Force Shield MkII (+4 Armor, +2 Imagination), 1 in-game Jazzy Pants (+2 Armor, +2 Imagination), 50 Faction Tokens, and 1 in-game Dark Blue Gem (worth 10,000 coins).
    </p>
    <p>
      The next 6 winners in each age group will receive: <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>, 1 in-game Murgle Blotch Shirt (+2 Imagination), 1 in-game Nexus Force Shield MkI (+4 Armor), 1 in-game Zazzy Pants (+1 Armor, +1 Imagination), 50 Faction Tokens, and 1 in-game Dark Red Gem (worth 5,000 coins).
    </p>
    <p>
      <b>2. HOW TO ENTER:</b>
    </p>
    <p>
      Log on to the LEGO Universe online game and complete the Battle of Nimbus Station minigame in the Nimbus Station zone between 12:01 a.m. EST Saturday, August 23, 2011 and 11:59 p.m. EST Sunday, August 24, 2011. Winners will be selected based on the speed at which they complete all 29 waves of the minigame. Players can enter by playing the minigame as many times as they like in order to improve their completion time, but no player will be awarded more than one prize.
    </p>
    <p>
      <b>3. HOW TO WIN:</b>
    </p>
    <p>
      Once you log in and complete the requirements, you will be automatically entered into the drawing; there is nothing more you need to do. Winners will be selected based on the speed at which they defeat all 29 waves of enemies. Decisions will be based on the subjective opinions of the Judges, and will be final.
    </p>
    <p>
      <b>MANY WILL ENTER; FEW WILL WIN A PRIZE.</b>
    </p>
    <p>
      <b>4. ELIGIBILITY:</b>
    </p>
    <p>
      This promotion is open only to LEGO Universe online game players who are residents of the 50 United States plus the District of Columbia, the United Kingdom, Germany, and Canada, except for the Province of Quebec. Employees of the LEGO Group, and members of their immediate families and households are not eligible to participate. Additionally, eligibility is limited to those who have not won a prize from any LEGO Universe promotion in the 30 days prior to the Prize Award date.
    </p>
    <p>
      All national, regional, and local laws and regulations apply. Void where prohibited by law.
    </p>
    <p>
      <b>5. AWARD OF PRIZES:</b>
    </p>
    <p>
      Ten (10) winners aged 13 and over and Ten (10) winners aged 12 and under will be picked from among all eligible entries on or about August 19, 2011 by members of the LEGO Universe Judge Team, whose decisions will be final. Odds of winning will depend upon the number of eligible entries received, and the skill of the entrant.
    </p>
    <p>
      <b>6. PRIZES AND APPROXIMATE RETAIL VALUES:</b>
    </p>
    <p>
      There will be three (3) sets of prizes awarded.
    </p>
    <p>
      The top winner from each age group will receive: <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7189&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Mill Village Raid set (#7189)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7188&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms King’s Carriage Ambush set (#7188)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7187&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Escape from the Dragon’s Prison set (#7187)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>, 1 in-game Clear Gem (worth 50,000 coins), 75 Faction Tokens, 1 in-game 4 Riders Shirt, 1 in-game Nexus Force Shield Mk III, and 1 in-game Snazzy Pants. (Estimated value: $129.97 USD)
    </p>
    <p>
      The next 3 winners in each age group will receive: <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7188&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms King’s Carriage Ambush set (#7188)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7187&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Escape from the Dragon’s Prison set (#7187)</a>, <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>, 1 in-game Kinga Hurl Shirt (+3 Imagination), 1 in-game Nexus Force Shield MkII (+4 Armor, +2 Imagination), 1 in-game Jazzy Pants (+2 Armor, +2 Imagination), 50 Faction Tokens, and 1 in-game Dark Blue Gem (worth 10,000 coins). (Estimated value: $109.98 USD)
    </p>
    <p>
      The next 6 winners in each age group will receive: <a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>, 1 in-game Murgle Blotch Shirt (+2 Imagination), 1 in-game Nexus Force Shield MkI (+4 Armor), 1 in-game Zazzy Pants (+1 Armor, +1 Imagination), 50 Faction Tokens, and 1 in-game Dark Red Gem (worth 5,000 coins). (Estimated value: $19.99 USD)
    </p>
    <p>
      No substitution of prizes will be allowed.
    </p>
    <p>
      <b>7. GENERAL RULES:</b>
    </p>
    <p>
      Winners will be notified by email to the winner's email address of record.
    </p>
    <p>
      No substitution of prize or transfer of prize to another person is permitted. Sponsor reserves the right to substitute a prize of equal or greater value in the unlikely event that the intended prize is not available, or in order to award a prize appropriate for the age of the winner.
    </p>
    <p>
      All submissions become the property of the LEGO Group. Sponsor reserves the right to modify the winning entry to make it technically suitable for the game.
    </p>
    <p>
      All decisions of the Judges are final.
    </p>
    <p>
      All income taxes and fees and/or duties, if applicable, are the sole responsibility of each winner.<br>In no event will more than the stated number of prizes be awarded.
    </p>
    <p>
      If an entrant submits more than the stated number of entries permitted, or if the Sponsor suspects that an entrant attempted to obtain additional entries by using multiple email addresses, registrations, identities, or any other method, all entries submitted by the entrant will be declared null and void.
    </p>
    <p>
      By accepting a prize, winners (and their parent or legal guardian if winner is a minor) agree to hold Sponsor, its directors, officers, employees, and assigns harmless against any and all claims and liability arising out of use of the prize. Winners (and their parent or legal guardian if winner is a minor) assume all liability for any injury or damage caused, or claimed to be caused, by participation in this promotion or use or redemption of any prize. Acceptance of a prize constitutes permission for the Sponsor to use winner's name, likeness, and entry submission for purposes of advertising and trade, including posting the winning entries on the LEGO Universe Community webpage, without further compensation, and to use a winner's name for purposes of publishing a winners list, unless prohibited by law.
    </p>
    <p>
      Prizes will be mailed to each winner when available, but not later than 90 days from the prize award date. It is the responsibility of the winner to inform the Sponsor of the proper shipping address.<br>Entrants (and their parent or legal guardian if entrant is a minor) agree to release, discharge and hold harmless Sponsor, its directors, officers, employees and assigns, harmless from and against any and all liability and damages. By participating in this promotion, entrants (and their parent or legal guardian if entrant is a minor) agree to be bound by the Official Rules and the judges’ decisions, which are final. In the event there is a discrepancy or inconsistency between disclosures or other statements contained in any promotion materials and the terms and conditions of the Official Rules, the Official Rules shall prevail, govern and control. Sponsor is not responsible for any typographical or other error in the printing of the offer, administration of the giveaway promotion, or in the announcement of the prizes.
    </p>
    <p>
      <b>Special rules for United States residents :</b><br> Winners who are residents of the United States (or a parent or legal guardian if a potential winner is deemed a minor in his/her state of residence) will be required to complete and return an Affidavit of Eligibility, Release of Liability, and a Prize Acceptance Form within 5 days of such forms being emailed. Noncompliance within this time period or return of any prize or prize notification as undeliverable will result in disqualification without further notice and an alternate winner will be selected. (End of US residents special rules).
    </p>
    <p>
      <b>Special rules for Canadian residents :</b><br> To be declared a winner, residents of Canada (or a parent or guardian if a potential winner is deemed a minor in his/her province or territory of residence) must correctly answer an arithmetical skill-testing question and execute a Declaration and Release Form to release Sponsor and its agencies and representatives from liability with respect to contests and prizes within 5 days of such form being emailed. Noncompliance within this time period or return of any prize or prize notification as undeliverable will result in disqualification without further notice and an alternate winner will be selected. (End of Canadian residents special rules).
    </p>
    <p>
      Not responsible for faulty, incorrect or mis-transcribed email transmissions, incorrect announcements of any kind, technical hardware or software failures of any kind including any injury or damage to any person's computer related to or resulting from participating in or experiencing any materials in connection with the promotion, lost or unavailable network connections, or failed, incomplete, garbled or delayed computer transmission that may limit a user's ability to participate in the promotion. Sponsor assumes no responsibility for undeliverable emails resulting from any form of active or passive email filtering by a user's internet service provider and/or email client or for insufficient space in user's email account to receive email. Sponsor reserves the right to cancel or modify the promotion if fraud, misconduct or technical failures destroy the integrity of the program; or if a computer virus, bug, or other technical problem corrupts the administration or security of the program as determined by Sponsor, in its sole discretion. In the event of termination of online entry, a notice will be posted online indicating how to enter via the mail. The drawing will be conducted from among all eligible online entries received prior to termination and all eligible mail-in entries received. Any damage made to the Web Site will be the responsibility of the authorized email account holder of the email address of record. Proof of submitting entries will not be deemed to be proof of receipt by Sponsor. Any entries which are suspected of being fraudulent (including those using robotic, automatic, programmed or similar methods of participation) will be disqualified, based on determinations made solely by Sponsor. Sponsor reserves the right to prohibit the participation of an individual if fraud or tampering is suspected or if the account holder fails to comply with any requirement of participation as stated herein or with any provision in these Official Rules.
    </p>
    <p>
      <b>Notice:</b> Any attempt by an individual to deliberately damage any web site or undermine the legitimate operation of this promotion is a violation of criminal and civil laws, and should such an attempt be made, sponsor reserves the right to seek damages from any such individual to the fullest extent permitted by law.
    </p>
    <p>
      <b>8. WINNERS:</b>
    </p>
    <p>
      For the names of the winners, send a separate, stamped, self-addressed (#10) envelope to: LEGO® Universe "Battle of Nimbus Station" Contest, LEGO Systems, Inc., 1795 Dogwood St, Suite 240, Louisville, CO 80027, USA, to be received by Sunday, September 25, 2011. Residents of Canada and the U.S. State of Vermont may omit return postage.
    </p>
    <p>
      <b>9. SPONSOR:</b>
    </p>
    <p>
       LEGO Systems, Inc., 555 Taylor Road, Enfield, CT 06083, USA.
    </p>
    <p>
      LEGO is a trademark of the LEGO Group of Companies. © 2011 The LEGO Group. All rights reserved.
    </p>
  </div>
</template>
